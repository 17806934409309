<template>
  <section class="AmbulanciasDetalle empresas__inner container mt24 mb24">
    <ButtonBackHome
      to="/empresas/ordenesdecompra"
      text="Regresar a mis ordenes de compra"
    />
    <header class="empresas__header">
      <h2 class="empresas_home__heading heading">Información de la orden de compra</h2>
    </header>
    <v-row v-if="cargando">
      <v-col cols="12">
        <v-skeleton-loader
          class="mx-auto"
          max-height="100"
          type="card"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        v-for="x in 2"
        :key="x"
      >
        <v-skeleton-loader
          class="mx-auto"
          type="list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line"
        />
      </v-col>
    </v-row>
    <v-row v-if="!cargando">
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <div
          class="clave_reservacion"
          style="min-width:100%"
        >
          <div class="clave_reservacion-value">
            <strong class="upper">{{ordencompra.id_com
            }}</strong>
          </div>
          <div class="clave_reservacion-text">Folio</div>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="9"
      >
        <v-alert
          v-if="hadTheirMoney && !hadComplemento"
          type="error"
          prominent
        >
          {{giveMeTheCFDI}}
        </v-alert>
      </v-col>
      <v-col
        sm="12"
        md="6"
      >
        <v-card outlined>
          <v-card-title>Información general</v-card-title>
          <v-list>
            <v-list-item
              :href="$api+'ordencompra/'+$session.get('token')+'/'+ordencompra.id"
              target="_blank"
            >
              <v-list-item-icon>
                <v-icon>print</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="upper nowrap">
                  Imprimir orden de compra
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <list-item
              :title="_doDiaMes(ordencompra.created_com)"
              subtitle="Fecha de solicitud"
              divider
            />

            <v-list v-if="!cargando">
              <!-- manejo de archivo de factura PDF -->
              <!-- manejo de archivo de factura PDF -->
              <!-- manejo de archivo de factura PDF -->
              <list-item
                v-if="ordencompra.pdf_com"
                divider
                hideContent
              >
                <template #actions>
                  <v-btn
                    class="mb16 mt16"
                    color="primary"
                    outlined
                    block
                    :href="$files+ordencompra.pdf_com"
                    target="_blank"
                  >
                    <v-icon>download</v-icon>&nbsp;factura PDF
                  </v-btn>
                  <!-- pdf_com -->
                </template>
              </list-item>
              <div v-else>
                <file-upload
                  style="margin: 0px 12px !important"
                  title="Subir factura PDF"
                  label="Archivo PDF de la factura"
                  path="compras/"
                  @file="_handleFileUploaded('pdf_com',$event)"
                />
                <v-divider />
              </div>
              <!-- manejo de archivo de factura XML -->
              <!-- manejo de archivo de factura XML -->
              <!-- manejo de archivo de factura XML -->
              <list-item
                divider
                hideContent
                v-if="ordencompra.xml_com"
              >
                <template #actions>
                  <v-btn
                    class="mb16 mt16"
                    color="primary"
                    outlined
                    block
                    :href="$files+ordencompra.xml_com"
                    target="_blank"
                  >
                    <v-icon>download</v-icon>&nbsp;factura XML
                  </v-btn>
                  <!-- xml_com -->
                </template>
              </list-item>
              <div v-else>
                <file-upload
                  style="margin: 0px 12px !important"
                  title="Subir factura XML"
                  label="Archivo XML de la factura"
                  path="compras/"
                  @file="_handleFileUploaded('xml_com',$event)"
                />
                <v-divider />
              </div>
              <list-item
                divider
                hideContent
                v-if="ordencompra.comprobante_pago_com"
              >
                <template #actions>
                  <v-btn
                    class="mb16 mt16"
                    color="primary"
                    outlined
                    block
                    :href="$files+ordencompra.comprobante_pago_com"
                    target="_blank"
                  >
                    <v-icon>download</v-icon>&nbsp;Comprobante de pago
                  </v-btn>
                  <!-- xml_com -->
                </template>r2
              </list-item>
              <!-- <metodo_pago_com -->
              <div v-if="!ordencompra.metodo_pago_com">
                <v-divider v-if="ordencompra.metodo_pago_com" />
                <list-item
                  title="Pendiente de pago"
                  divider
                />
              </div>
              <div v-else>
                <list-item
                  :title="ordencompra.metodo_pago_com.toUpperCase()"
                  :subtitle="'Método de pago'"
                  divider
                />
                <list-item
                  :title="_doDiaMes(ordencompra.fecha_pago_com)"
                  :subtitle="'Fecha de pago'"
                  divider
                />
              </div>
              <!-- <div v-if="(ordencompra.estatus_com == 'confirmada' || compra.estatus_com== 'comprada') && compra.factura_com"> -->
              <div v-if="ordencompra.comprobante_pago_com">
                <list-item
                  v-if="ordencompra.complemento_pago_pdf_com"
                  divider
                  hideContent
                >
                  <template #actions>
                    <v-btn
                      class="mb16 mt16"
                      color="primary"
                      outlined
                      block
                      :href="$files+ordencompra.complemento_pago_pdf_com"
                      target="_blank"
                    >
                      <v-icon>download</v-icon>&nbsp;complemento de pago PDF
                    </v-btn>
                    <!-- complemento_pago_pdf_com -->
                  </template>
                </list-item>
                <div v-else>
                  <file-upload
                    style="margin: 0px 12px !important"
                    title="Subir complemento de pago PDF"
                    label="Archivo PDF del complemento"
                    path="compras/"
                    @file="_handleFileUploaded('complemento_pago_pdf_com',$event)"
                  />
                  <v-divider />
                </div>
                <list-item
                  divider
                  hideContent
                  v-if="ordencompra.complemento_pago_xml_com"
                >
                  <template #actions>
                    <v-btn
                      class="mb16 mt16"
                      color="primary"
                      outlined
                      block
                      :href="$files+ordencompra.complemento_pago_xml_com"
                      target="_blank"
                    >
                      <v-icon>download</v-icon>&nbsp;complemento de pago XML
                    </v-btn>
                    <!-- complemento_pago_xml_com -->
                  </template>
                </list-item>
                <div v-else>
                  <file-upload
                    style="margin: 0px 12px !important"
                    title="Subir complemento de pago XML"
                    label="Archivo XML del complemento"
                    path="compras/"
                    @file="_handleFileUploaded('complemento_pago_xml_com',$event)"
                  />
                </div>
              </div>
            </v-list>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        sm="12"
        md="6"
      >
        <v-card outlined>
          <v-card-title>Productos o servicios requeridos</v-card-title>
          <v-list>
            <list-item
              class="upper"
              v-for="(pto,idxp) in ordencompra.productos"
              :key="idxp"
              :title="pto.cantidad_cmp +' x '+pto.nombre_pto"
              :subtitle="pto.descripcion_pto"
              :thirdtitle="'Cantidad: '+pto.cantidad_cmp"
              :divider="idxp+1 < ordencompra.productos.length"
            />
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <div
      v-if="hadTheirMoney && !hadComplemento"
      class="mt16 mb24"
    >
      <v-alert
        v-if="hadTheirMoney && !hadComplemento"
        type="error"
        prominent
      >
        {{giveMeTheCFDI}}
      </v-alert>
    </div>
  </section>
</template>
<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
import dates from "@/mixins/dates.js";
import HeaderTitle from "@/components/header/HeaderTitle.vue";
import ListItem from "@/components/lists/ListItem.vue";
import FileUpload from "@/components/forms/files/FileUpload.vue";
export default {
  name: "AmbulanciasDetalle",
  mixins: [standard, forms, dates],
  components: {
    HeaderTitle,
    ListItem,
    FileUpload,
  },
  data() {
    return {
      name: "",
      cargando: true,
      enviandoCarta: false,
      showContactsDialog: false,
      //as
      transition: "fade-transition",
      ordencompra: {
        cfdi_uso_com: null,
        complemento_pago_pdf_com: null,
        complemento_pago_xml_com: null,
        comprobante_pago_com: null,
        created_com: null,
        estatus_com: null,
        factura_com: null,
        fecha_pago_com: null,
        id: null,
        id_com: null,
        id_coo_com: null,
        id_pro_com: null,
        id_usr_com: null,
        importe_com: null,
        metodo_pago_com: null,
        observaciones_com: null,
        pdf_com: null,
        productos: [],
        updated_com: null,
        uuid_com: null,
        xml_com: null,
      },
      hadTheirMoney: false,
      hadComplemento: false,
      giveMeTheCFDI: "",
      ruta: "ordenescompra/",
    };
  },
  methods: {
    _getOrdenCompra() {
      this._getThings(this.ruta, "ordencompra");
    },
    _handleFileUploaded(col, file) {
      this.ordencompra[col] = file;
      this.$emit("msg", "Se ha cargado correctamente el archivo");
      this._updateCompra();
    },
    _updateCompra(otherFuntion) {
      let compra = Object.assign({}, this.ordencompra);
      if (otherFuntion && otherFuntion == "pago") {
        compra.metodo_pago_com = this.metodo_pago_com;
        compra.fecha_pago_com = this.fecha_pago_com;
      }
      const data = { compra: compra };
      this.cargando = true;
      this._updateAll(this.ruta, data, "msg", "_handleUpdateResponse");
    },
    _handleUpdateResponse(res) {
      this.$emit("msg", res.msg);
      if (this.metodo_pago_com || this.fecha_pago_com) this._getCompraDetalle();
      this.$emit("updated", true);
    },
  },
  created() {
    this.ruta = "ordenescompra/" + this.$route.params.id;
    this._getOrdenCompra();
  },
  watch: {
    $route(a, b) {
      this.ruta = "ordenescompra/" + this.$route.params.id;
      this._getOrdenCompra();
    },
    ordencompra(a) {
      const {
        comprobante_pago_com,
        complemento_pago_pdf_com,
        complemento_pago_xml_com,
      } = a;
      this.hadTheirMoney = comprobante_pago_com ? true : false;
      this.hadComplemento =
        complemento_pago_pdf_com && complemento_pago_xml_com ? true : false;
      this.giveMeTheCFDI =
        "Es importante cargues tu complemento de pago CFDI en formato PDF y XML";
    },
  },
};
</script>
<style lang="scss">
</style>